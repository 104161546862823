<template>
  <div class="container" v-if="!isLoading">
    <div class="audit-trail" v-if="braceReissueList.length">
      <div class="audit-trail-header">
        <div class="information">{{ $t('braceReissue.bfxx') }}</div>
        <div class="time">{{ $t('braceReissue.sqsj') }}</div>
        <div class="result">{{ $t('braceReissue.shjg') }}</div>
        <div class="introduce">{{ $t('braceReissue.shsm') }}</div>
        <div class="action">操作</div>
      </div>
      <div class="list-content">
        <div class="list-item" v-for="(item, index) in braceReissueList" :key="index">
          <div class="information">{{ item.content }}</div>
          <div class="time">{{ item.createDatetime }}</div>
          <div :class="`result result-${statusRender[item.status].key}`">
            <div :class="`result-icon`"></div> {{ item.status === '0' ? (item.isSubmitModel === '1' &&
              item.isCompleteLogistics === '0') ? $t('braceReissue.dyjmx') : statusRender[item.status].value :
              statusRender[item.status].value }}
          </div>
          <div :title="item.description" class="introduce">{{ item.description }}</div>
          <div class="action" @click="doAction(item)">{{ item.status !== '0' ? $t('braceReissue.cxtj') : (item.isSubmitModel === '1' &&
              item.isCompleteLogistics === '0') ? $t('braceReissue.yjmx') : '' }}</div>
        </div>
      </div>
      <div class="list-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]" :page-size="currentPageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="braceReissueObj.total">
        </el-pagination>
      </div>
    </div>
    <div class="empty-render" v-else>
      {{ $t('braceReissue.zwshjl') }}
    </div>
  </div>
</template>

<script>
import {
  bracesReissuePage,
} from "common/api/cases";
export default {
  props: {
    caseDetails: {
      required: true
    },
    reload: {
      required: false
    }
  },
  watch: {
    reload: {
      handler: function() {
        this.getBracesReissuePage()
      },
      deep: true
    }
  },
  data() {
    return {
      isLoading: true,
      braceReissueObj: {},
      braceReissueList: [],
      statusRender: {
        0: { value: this.$t('braceReissue.shz'), key: 'wait' },
        1: { value: this.$t('braceReissue.tg'), key: 'pass' },
        2: { value: this.$t('braceReissue.btg'), key: 'not' }
      },
      currentPage: 1,
      currentPageSize: 10
    }
  },
  methods: {
    handleSizeChange(val) {
      this.currentPageSize = val
      this.getBracesReissuePage();
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getBracesReissuePage();
    },
    doAction(val) {
      this.$emit('doAction', val);
    },
    getBracesReissuePage() {
      bracesReissuePage({
        pageNum: this.currentPage,
        pageSize: this.currentPageSize,
        caseNumber: this.caseDetails['caseNumber'],
        statusList: ['0', '1', '2'],
        sort: '-createDatetime'
      }).then((res) => {
        this.isLoading = false;
        this.braceReissueList = res.list;
        this.braceReissueObj = res
      })
    }
  },
  mounted() {
    this.getBracesReissuePage()
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 30px;
  max-height: 1462px;
}

.empty-render {
  height: 400px;
  font-size: 24px;
  line-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-pagination {
  margin-top: 50px;
}

.audit-trail {

  .information {
    width: 442px;
  }

  .time {
    width: 198px;
  }

  .result {
    width: 168px;
    display: flex;
    align-items: center;

    .result-icon {
      width: 4px;
      height: 4px;
      padding: 4px;
      border-radius: 50%;
      border: 4px solid;
      background-color: #ffffff;
      margin-right: 8px;
    }

  }

  .result-wait {
    color: #333333;

    .result-icon {
      border-color: #fcc80e;
    }

  }

  .result-pass {
    color: #20C479;

    .result-icon {
      border-color: #20C479;
    }
  }

  .result-not {
    color: #ED4027;

    .result-icon {
      border-color: #ED4027;
    }
  }

  .introduce {
    width: 275px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .action {
    flex: 1;
    padding-left: 32px;
  }

  .audit-trail-header {
    padding-left: 32px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
  }

  .list-content {
    width: 100%;

    .action {
      cursor: pointer;
    }

    .list-item {
      width: 100%;
      min-height: 80px;
      padding: 40px 32px;
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 20px;
      background-color: #f9f9f9;
      display: flex;
      align-items: center;

      >div {
        padding-right: 32px;
      }

    }

  }

}
</style>