<template>
  <div class="braces-reissue-table">
    <div class="batch-info-card">
      <div class="batch-info-tips">
        1.{{ $t('braceReissue.dczsczyts') }}<br />2.{{
          $t('braceReissue.ytsqznsq')
        }}
      </div>
      <div
        v-show="
          'toothSetCount' in caseDetails['curePlanInfo'] ||
          'toothSetDownJawCount' in caseDetails['curePlanInfo']
        "
        class="jaw-batch"
      >
        {{
          `${$t('casesDetail.casesDetail.shzz')}
                ${get(caseDetails.curePlanInfo, 'toothSetCount')}
                ${$t('casesDetail.casesDetail.bu')}，
                ${$t('casesDetail.casesDetail.xhzz')}
                ${get(caseDetails.curePlanInfo, 'toothSetDownJawCount')}
                ${$t('casesDetail.casesDetail.bu')}`
        }}
      </div>
      <div
        v-show="
          'nowStepUpjaw' in caseDetails['curePlanInfo'] ||
          'nowStepDownjaw' in caseDetails['curePlanInfo']
        "
        class="factory-batch"
      >
        {{ $t('casesDetail.casesDetail.gcffz') }}
        {{ $t('casesDetail.casesDetail.shd') }}
        {{ caseDetails['curePlanInfo'].nowStepUpjaw }}
        {{ $t('casesDetail.casesDetail.bu') }}，
        {{ $t('casesDetail.casesDetail.xhd') }}
        {{ caseDetails['curePlanInfo'].nowStepDownjaw }}
        {{ $t('casesDetail.casesDetail.bu')
        }}<span @click="handleShowBatchInfo">{{
          $t('braceReissue.ckmpzh')
        }}</span>
      </div>
      <div v-if="aidLogisticsFlag !== '0'" class="to-express">
        {{ $t('braceReissue.czdyjmx') }}，<span @click="toExpress">{{
          $t('braceReissue.djqwyj')
        }}</span>
      </div>
    </div>
    <div v-if="isLoading" class="loading-content">
      <img
        alt=""
        class="loading-icon"
        src="../../../../../components/loading/loading.gif"
      />
    </div>
    <div v-else>
      <div class="questions-content">
        <div class="case-title required-title">
          1.{{ $t('braceReissue.ytxx') }}
        </div>
        <div v-if="showTooth" class="wrap">
          <div class="question-tips">{{ $t('braceReissue.qzxmstnxz') }}</div>
          <div
            v-if="
              'maxillary' in curePlanInfo && curePlanInfo['maxillary'] !== 0
            "
            class="upper-batch-tabs"
          >
            {{ $t('braceReissue.shb') }}：
            <div
              v-for="(item, index) in upperCountInfo"
              :key="index"
              :class="[
                nowChoosenUpperIndex === index
                  ? 'batch-tabs-item-active'
                  : 'batch-tabs-item',
              ]"
              @click="nowChoosenUpperIndex = index"
            >
              {{ item.start + ' - ' + item.end }}
            </div>
          </div>
          <div
            v-if="upperCountInfo[nowChoosenUpperIndex]"
            class="upper-batch-info"
          >
            <el-popover
              v-for="(item, index) in upperCountInfo[nowChoosenUpperIndex].end -
              upperCountInfo[nowChoosenUpperIndex].start +
              1"
              :key="index"
              :title="$t('braceReissue.czxz')"
              :visible-arrow="false"
              placement="bottom-start"
              popper-class="batch-popover"
              trigger="click"
              width="200"
              @show="
                handleShow(
                  upperCountInfo[nowChoosenUpperIndex].start + index,
                  nowChoosenUpperIndex,
                  'upper'
                )
              "
            >
              <div class="plastics-content">
                <div
                  v-for="(cItem, cIndex) in plasticsSheetList"
                  :key="cIndex"
                  :class="
                    renderClassName(
                      upperCountInfo[nowChoosenUpperIndex].start + index,
                      cItem,
                      'toothUpStepInfo'
                    )
                  "
                  @click="
                    changeItemStep(
                      upperCountInfo[nowChoosenUpperIndex].start + index,
                      cItem,
                      'toothUpStepInfo'
                    )
                  "
                >
                  <div :title="cItem['name']">{{ cItem['name'] }}</div>
                </div>
              </div>
              <div
                slot="reference"
                :class="
                  upperCountInfo[nowChoosenUpperIndex].start + index in
                  toothUpStepInfo
                    ? 'bacth-info-item-active'
                    : 'bacth-info-item'
                "
              >
                {{ upperCountInfo[nowChoosenUpperIndex].start + index }}
              </div>
            </el-popover>
          </div>

          <div
            v-if="
              'mandibular' in curePlanInfo && curePlanInfo['mandibular'] !== 0
            "
            class="lower-batch-tabs"
          >
            {{ $t('braceReissue.xhb') }}：
            <div
              v-for="(item, index) in lowerCountInfo"
              :key="index"
              :class="[
                nowChoosenLowerIndex === index
                  ? 'batch-tabs-item-active'
                  : 'batch-tabs-item',
              ]"
              @click="nowChoosenLowerIndex = index"
            >
              {{ item.start + ' - ' + item.end }}
            </div>
          </div>
          <div
            v-if="lowerCountInfo[nowChoosenLowerIndex]"
            class="lower-batch-info"
          >
            <el-popover
              v-for="(item, index) in lowerCountInfo[nowChoosenLowerIndex].end -
              lowerCountInfo[nowChoosenLowerIndex].start +
              1"
              :key="index"
              :title="$t('braceReissue.czxz')"
              :visible-arrow="false"
              placement="bottom-start"
              popper-class="batch-popover"
              trigger="click"
              width="200"
              @show="
                handleShow(
                  lowerCountInfo[nowChoosenLowerIndex].start + index,
                  nowChoosenLowerIndex,
                  'lower'
                )
              "
            >
              <div class="plastics-content">
                <div
                  v-for="(cItem, cIndex) in plasticsSheetList"
                  :key="cIndex"
                  :class="
                    renderClassName(
                      lowerCountInfo[nowChoosenLowerIndex].start + index,
                      cItem,
                      'toothDownStepInfo'
                    )
                  "
                  @click="
                    changeItemStep(
                      lowerCountInfo[nowChoosenLowerIndex].start + index,
                      cItem,
                      'toothDownStepInfo'
                    )
                  "
                >
                  <div :title="cItem['name']">{{ cItem['name'] }}</div>
                </div>
              </div>
              <div
                slot="reference"
                :class="
                  lowerCountInfo[nowChoosenLowerIndex].start + index in
                  toothDownStepInfo
                    ? 'bacth-info-item-active'
                    : 'bacth-info-item'
                "
              >
                {{ lowerCountInfo[nowChoosenLowerIndex].start + index }}
              </div>
            </el-popover>
          </div>
          <div
            v-if="
              Object.keys(toothUpStepInfo).length ||
              Object.keys(toothDownStepInfo).length
            "
            class="batch-changed-tips"
          >
            <div
              v-if="Object.keys(toothUpStepInfo).length"
              class="batch-tips-item"
            >
              {{
                `${$t('braceReissue.shb')}：${renderBatchTips(
                  'toothUpStepInfo'
                )}`
              }}
            </div>
            <div
              v-if="Object.keys(toothDownStepInfo).length"
              class="batch-tips-item"
            >
              {{
                `${$t('braceReissue.xhb')}：${renderBatchTips(
                  'toothDownStepInfo'
                )}`
              }}
            </div>
          </div>
        </div>
        <div v-else class="no-batch-data">
          <img alt="" src="../../../../../common/imgs/case/no-batch.png" />
          <div>暂无数据 ~</div>
        </div>
      </div>
      <div class="questions-content">
        <div class="case-title required-title">
          2.{{ $t('cases.createUser.hzjsyt') }}
        </div>
        <div class="wrap">
          <CommonButton
            :echoContent="resultObj['receiveType']"
            :renderList="[
              { key: '1', value: $t('cases.createUser.zssh') },
              { key: '3', value: $t('yssh.yssh') },
              { key: '2', value: $t('cases.createUser.trsh') },
            ]"
            parentKey="receiveType"
            @buttonChange="buttonHandlerChange"
          />
          <ul v-show="resultObj.receiveType === '2'">
            <li class="f_single">
              <div
                :style="
                  language === 'en_US'
                    ? 'width: 2rem; text-align: left;margin-right:0'
                    : ''
                "
                class="f_single_left"
              >
                {{ $t('cases.createUser.znjx') }}：
              </div>
              <div class="iup_box">
                <div class="f_single_right">
                  <textarea
                    v-model="address"
                    :placeholder="$t('cases.createUser.znjxplaceholder')"
                    class="message-add"
                    rows="3"
                  />
                  <a class="message-parse" @click="getMessage">{{
                    $t('cases.createUser.jx')
                  }}</a>
                  <span class="message-reset" @click="resetMessage">{{
                    $t('cases.createUser.qk')
                  }}</span>
                </div>
              </div>
            </li>
            <li class="f_single">
              <div
                :style="
                  language === 'en_US'
                    ? 'width: 2rem; text-align: left;margin-right:0'
                    : ''
                "
                class="f_single_left"
              >
                <span class="d_require">●</span>
                {{ $t('cases.createUser.sjrxm') }}：
              </div>
              <div class="f_single_right">
                <input
                  v-model="resultObj.receiveUser"
                  :placeholder="$t('cases.createUser.qtxsjrxm')"
                />
              </div>
            </li>

            <li class="f_single">
              <div
                :style="
                  language === 'en_US'
                    ? 'width: 2rem; text-align: left;margin-right:0'
                    : ''
                "
                class="f_single_left"
              >
                <span class="d_require">●</span>
                {{ $t('cases.createUser.sjrsjhm') }}：
              </div>
              <div class="f_single_right" style="display: flex">
                <el-select
                  v-model="resultObj.contactWay"
                  @change="changeContactWay"
                >
                  <el-option
                    v-for="item in contactWayList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  >
                  </el-option>
                </el-select>
                <input
                  v-if="resultObj.contactWay == 1"
                  v-model="resultObj.phone"
                  :placeholder="$t('cases.createUser.qsrsjrsjhm')"
                  style="margin-left: 0.2rem"
                  type="number"
                />
                <div
                  v-if="resultObj.contactWay == 2"
                  style="
                    margin-left: 0.2rem;
                    display: flex;
                    align-items: center;
                  "
                >
                  <input
                    v-model="resultObj.phoneArea"
                    :placeholder="$t('birdie.qh')"
                    style="width: 1rem"
                    type="number"
                  />
                  <div
                    style="
                      width: 0.3rem;
                      height: 1px;
                      background: #bbbbbb;
                      margin: 0 0.1rem;
                    "
                  ></div>
                  <input
                    v-model="resultObj.phone"
                    :placeholder="$t('birdie.dhhm')"
                    type="number"
                  />
                </div>
              </div>
            </li>

            <li class="f_single" style="align-items: self-start">
              <div
                :style="
                  language === 'en_US'
                    ? 'width: 2rem; text-align: left;margin-right:0'
                    : ''
                "
                class="f_single_left"
                style="padding-top: 0.1rem"
              >
                <span class="d_require">●</span>
                {{ $t('cases.createUser.dz') }}：
              </div>
              <div class="f_single_right">
                <div class="select_box">
                  <el-cascader
                    v-model="resultObj.provinces"
                    :options="cityData"
                    :placeholder="$t('birdie.qxzdq')"
                    :props="{
                      value: 'id',
                      label: 'name',
                      children: 'sub',
                    }"
                    class="input_width"
                  ></el-cascader>
                </div>
                <div class="iup_box flex-y-c">
                  <div
                    :style="
                      language === 'en_US'
                        ? 'width: 1.8rem; margin-left: -2.4rem;margin-right: 0.6rem'
                        : 'margin-left: -1.17rem;'
                    "
                    class="f_single_left"
                  >
                    <span class="d_require">●</span>
                    {{ $t('cases.createUser.xxdz') }}：
                  </div>
                  <input
                    v-model="resultObj.address"
                    :placeholder="$t('cases.createUser.xxdz')"
                    type="text"
                  />
                </div>
              </div>
            </li>
          </ul>
          <div v-if="resultObj.receiveType === '3'" class="doctor-address">
            <div class="address-label">{{ $t('yssh.ysshdz') }}：</div>
            <div class="single_right">
              <div
                :class="
                  doctorAddressId === ''
                    ? 'doctor-select'
                    : 'doctor-select-active'
                "
                :title="
                  doctorAddressId === ''
                    ? $t('yssh.djdzxz')
                    : renderAddressText()
                "
                @click="showDoctorAddressDia"
              >
                {{
                  doctorAddressId === ''
                    ? $t('yssh.djdzxz')
                    : renderAddressText()
                }}
              </div>
              <span @click="showDoctorAddressDia">{{ $t('yssh.dzxz') }}</span>
            </div>
          </div>
          <div v-if="resultObj.receiveType === '1'" class="receive-address">
            <div class="address-label">{{ $t('braceReissue.dzxz') }}：</div>
            <el-select
              v-model="resultObj.receiveAddressId"
              :placeholder="$t('braceReissue.qxzzsshdz')"
              class="address-select"
              clearable
            >
              <el-option
                v-for="item in clinicAddressList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="questions-content">
        <div class="case-title">3.{{ $t('cases.createImage.yemx') }}</div>
        <div class="wrap">
          <div class="question-tips">
            1.{{ $t('braceReissue.mrbxytjyhmx') }}
          </div>
          <div class="question-tips">2.{{ $t('braceReissue.rgxycyxdmx') }}</div>
          <CommonButton
            :echoContent="resultObj['isSubmitModel']"
            :renderList="[
              { key: '1', value: $t('braceReissue.tj') },
              { key: '0', value: $t('braceReissue.btj') },
            ]"
            parentKey="isSubmitModel"
            @buttonChange="buttonHandlerChange"
          />
          <div v-if="resultObj['isSubmitModel'] === '1'">
            <div class="button-filed-item">
              <div
                :style="{ width: language == 'zh_CN' ? '1.0rem' : '1.4rem' }"
                class="label"
              >
                {{ $t('cases.createImage.gxjmx') }}
              </div>
              <div class="filed-value">
                <CommonButton
                  :echoContent="resultObj['submitModelType']"
                  :renderList="siliconeRubberModel"
                  parentKey="submitModelType"
                  @buttonChange="buttonHandlerChange"
                />
              </div>
            </div>
            <div
              v-if="resultObj['submitModelType'] === 'silicagel_print_model'"
              class="print-model"
            >
              <div class="tips">
                {{ $t('cases.createImage.yscblh') }}
              </div>
              <div class="send-express-container">
                <div class="case-number">
                  {{ $t('cases.cases.blh') }}：
                  <input
                    id="caseNumber"
                    :value="caseDetails.caseNumber"
                    readonly
                    type="text"
                  />
                </div>
                <div
                  ref="copyCases"
                  class="copy"
                  @click="copyCasesNumber"
                ></div>
                <div class="send" @click="sendExpress">
                  <img
                    alt=""
                    class="express-icon"
                    src="/static/images/createCases/pc_doc_shunfeng.png"
                  />
                  {{ $t('cases.createImage.fkd') }}
                </div>
              </div>
            </div>
            <div class="button-filed-item">
              <div
                :style="{ width: language == 'zh_CN' ? '1.0rem' : '1.4rem' }"
                class="label"
              >
                {{ $t('cases.createImage.szmxwz') }}
              </div>
              <div class="filed-value">
                <CommonButton
                  :echoContent="resultObj['submitModelType']"
                  :renderList="digitalModelFile"
                  parentKey="submitModelType"
                  @buttonChange="buttonHandlerChange"
                />
              </div>
            </div>
            <div
              v-show="resultObj['submitModelType'] === 'local_upload'"
              :style="{
                marginLeft: language === 'zh_CN' ? '1.2rem' : '1.6rem',
              }"
            >
              <div class="s_c__up">
                {{ $t('cases.createImage.se') }}
                <el-upload
                  ref="sharpUpJaw"
                  :action="UPLOAD_URL"
                  :before-upload="
                    (file) =>
                      beforeFileUpload(file, 'sharp_upjaw_reissue', 'stl')
                  "
                  :data="{ token: uploadToken }"
                  class="upload-file"
                  drag
                >
                  <span class="up_load">{{
                    $t('cases.createImage.scstl')
                  }}</span>
                </el-upload>
                <p v-show="fileNameObj['sharp_upjaw_reissue']" class="up_file">
                  {{ fileNameObj['sharp_upjaw_reissue'] }}
                  <span class="up_status el-icon-success"></span>
                </p>
              </div>
              <div class="s_c__up">
                {{ $t('cases.createImage.xe') }}
                <el-upload
                  ref="sharpDownJaw"
                  :action="UPLOAD_URL"
                  :before-upload="
                    (file) =>
                      beforeFileUpload(file, 'sharp_downjaw_reissue', 'stl')
                  "
                  :data="{ token: uploadToken }"
                  class="upload-file"
                  drag
                >
                  <span class="up_load">{{
                    $t('cases.createImage.scstl')
                  }}</span>
                </el-upload>
                <p
                  v-show="fileNameObj['sharp_downjaw_reissue']"
                  class="up_file"
                >
                  {{ fileNameObj['sharp_downjaw_reissue'] }}
                  <span class="up_status el-icon-success"></span>
                </p>
              </div>
            </div>
            <div
              v-show="resultObj['submitModelType'] === 'iTero'"
              :style="{
                marginLeft: language === 'zh_CN' ? '1.2rem' : '1.6rem',
              }"
            >
              <div class="s_c__up">
                {{ $t('cases.createImage.wj') }}
                <el-upload
                  ref="iTero"
                  :action="UPLOAD_URL"
                  :before-upload="
                    (file) => beforeFileUpload(file, 'iTero', 'zip')
                  "
                  :data="{ token: uploadToken }"
                  class="upload-file"
                  drag
                >
                  <span class="up_load">{{
                    $t('cases.createImage.sczip')
                  }}</span>
                </el-upload>
                <p v-show="fileNameObj['iTero_reissue']" class="up_file">
                  {{ fileNameObj['iTero_reissue'] }}
                  <span class="up_status el-icon-success"></span>
                </p>
              </div>
            </div>
            <div
              v-show="resultObj['submitModelType'] === 'other'"
              :style="{
                marginLeft: language === 'zh_CN' ? '1.2rem' : '1.6rem',
              }"
            >
              <div class="s_c__up">
                {{ $t('cases.createImage.wj') }}
                <el-upload
                  ref="otherFile"
                  :action="UPLOAD_URL"
                  :before-upload="
                    (file) =>
                      beforeFileUpload(
                        file,
                        'other_file_reissue',
                        'zip',
                        'other'
                      )
                  "
                  :data="{ token: uploadToken }"
                  :file-list="fileNameObj['other_file_reissue']"
                  :limit="10"
                  :on-exceed="otherListInit"
                  :show-file-list="false"
                  class="upload-file"
                  drag
                  multiple
                >
                  <span class="up_load">{{
                    $t('cases.createImage.sczip')
                  }}</span>
                </el-upload>
                <ul
                  v-show="
                    fileNameObj['other_file_reissue'] &&
                    fileNameObj['other_file_reissue'].length
                  "
                >
                  <li
                    v-for="(item, index) in fileNameObj['other_file_reissue']"
                    :key="index"
                    class="up_file other_file"
                  >
                    {{ item.name }}
                    <span class="up_status el-icon-success"></span>
                    <span
                      class="up_status el-icon-close curp"
                      @click="deleteOtherFile(index, item.id)"
                    ></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="questions-content">
        <div class="case-title">4.{{ $t('braceReissue.sqyy') }}</div>
        <div class="wrap">
          <el-input
            v-model="resultObj.reason"
            :autosize="{ minRows: 4, maxRows: 6 }"
            :placeholder="$t('common.common.qsrnr')"
            class="tex_b_a"
            maxlength="2000"
            show-word-limit
            type="textarea"
          ></el-input>
        </div>
      </div>
    </div>
    <el-dialog
      :show-close="false"
      :visible.sync="showBatchInfo"
      class="modal_content"
      width="45%"
    >
      <div slot="title" class="modal_header">
        <div
          class="case_title_box"
          style="text-align: left; padding-left: 20px; padding-bottom: 10px"
        >
          <div>{{ $t('braceReissue.mpzh') }}</div>
        </div>
        <div class="el-icon-close" @click="showBatchInfo = false" />
      </div>

      <div class="batch-info-content">
        <div class="batch-item">
          <div class="item-label">牙套总步数</div>
          <div class="item-upjaw">
            {{
              $t('cases.createDiagnosis.se') +
              '：' +
              get(caseDetails.curePlanInfo, 'maxillary')
            }}
          </div>
          <div class="item-downjaw">
            {{
              $t('cases.createDiagnosis.xe') +
              '：' +
              get(caseDetails.curePlanInfo, 'mandibular')
            }}
          </div>
        </div>
        <div v-for="(item, index) in upSteps" :key="index" class="batch-item">
          <div class="item-label">
            {{
              `${$t('casesDetail.casesChildren.lookWearingRecord.d')}${
                index + 1
              }批次`
            }}
          </div>
          <div style="display: flex; justify-content: space-between; flex: 1">
            <div class="item-upjaw">
              {{ $t('cases.createDiagnosis.se') + '：' + item }}
            </div>
            <div class="item-sheet" style="flex: 1">
              {{
                $t('casesDetail.casesChildren.casesDoKeep.cz') +
                '：' +
                upSheetList[index]
              }}
            </div>
          </div>
          <div style="display: flex; justify-content: space-between; flex: 1">
            <div class="item-downjaw">
              {{ $t('cases.createDiagnosis.xe') + '：' + downSteps[index] }}
            </div>
            <div class="item-sheet" style="flex: 1">
              {{
                $t('casesDetail.casesChildren.casesDoKeep.cz') +
                '：' +
                downSheetList[index]
              }}
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="footer-cancel" @click="showBatchInfo = false">
          {{ $t('casesDetail.casesChildren.casesDoKeep.qx') }}
        </div>
        <div
          class="footer-confirm"
          type="primary"
          @click="showBatchInfo = false"
        >
          {{ $t('casesDetail.casesChildren.casesDoKeep.qd') }}
        </div>
      </span>
    </el-dialog>
    <el-dialog
      :show-close="false"
      :visible="showDoctorAddress"
      class="address-dia"
      width="588px"
      @close="showDoctorAddress = false"
    >
      <div class="dia-header">
        <div class="title">
          {{ $t('yssh.dzxz') }}
          <div class="add-address" @click="addDoctorAddress">
            <img
              alt=""
              src="../../../../../common/imgs/personal/position.png"
            />{{ $t('yssh.xzdz') }}
          </div>
        </div>
        <i
          class="el-icon-close close-dia"
          @click.stop="showDoctorAddress = false"
        />
        <!--        <img-->
        <!--          @click.stop="showDoctorAddress = false"-->
        <!--          class="close-dia"-->
        <!--          src="../../../../../common/imgs/expressage_birdie/dia_close.png"-->
        <!--          alt=""-->
        <!--        />-->
      </div>
      <div class="dia-content">
        <div v-if="!addressList.length" class="no-data">
          <img
            alt=""
            src="../../../../../common/imgs/personal/no-address.png"
          />
          <span>{{ $t('yssh.nzwtjrhshdz') }}</span>
        </div>
        <div
          v-for="(item, index) in addressList"
          :key="index"
          class="address-item"
        >
          <AddressCard
            :info="item"
            :isCreated="true"
            :showDefault="true"
            @cancel="editCurrent"
            @confirm="choosenCurrent"
          />
        </div>
      </div>
      <div class="dia-footer"></div>
    </el-dialog>
    <AddressEdit
      :editId="editId"
      :isEdit="isEdit"
      :visible="visible"
      @cancel="visible = false"
      @confirm="confirmAddress"
    />
    <FullLoading v-show="uploading" />
    <ExBirdie
      ref="exBirdie"
      :caseDetail="caseDetails"
      :defaultKey="exDefaultKey"
      caseType="1"
      refType="2"
      @tabsChange="tabsChanged"
    />
  </div>
</template>

<script>
import {
  PublicPlasticSheetList,
  clinicAddress,
  bracesReissueDetail,
  PublicSendAddressList,
  detailCurePlan,
} from 'common/api/cases';
import AddressCard from 'components/address-card/index.vue';
import AddressEdit from 'components/address-edit/index.vue';
import FullLoading from 'components/full-loading/full-loading';
import ExBirdie from 'components/expressage_birdie/index';
import { UPLOAD_URL } from 'common/js/config';
import * as QINIU from 'qiniu-js';
import { notifyMsg } from 'common/js/util';
import { getQiniuToken } from 'common/api/public';
import CommonButton from './common-button.vue';
import AddressParse from 'address-parse';
import { getCityList } from 'common/api/public';
import { get } from 'lodash';
import { mapState } from 'vuex';

export default {
  props: {
    caseDetails: {
      required: true,
    },
    reissueId: {
      required: false,
    },
    aidLogisticsFlag: {
      required: false,
    },
    modelType: {
      required: false,
    },
  },
  components: {
    CommonButton,
    ExBirdie,
    FullLoading,
    AddressCard,
    AddressEdit,
  },
  data() {
    return {
      showDoctorAddress: false,
      doctorAddressId: '',
      doctorAddressInfo: {},
      addressList: [],
      editId: '',
      isEdit: false,
      visible: false,
      UPLOAD_URL,
      exDefaultKey: '',
      isLoading: true,
      plasticsBatchList: [],
      lowerBatchList: [],
      upperBatchList: [],
      lowerCountInfo: [],
      upperCountInfo: [],
      plasticsSheetList: [],
      plasticsObj: {},
      nowChoosenUpperIndex: 0,
      nowChoosenLowerIndex: 0,
      toothUpStepInfo: {},
      toothDownStepInfo: {},
      upSteps: [],
      downSteps: [],
      upSheetList: [],
      downSheetList: [],
      resultObj: {
        receiveUser: '',
        receiveType: '1',
        receiveAddressId: '',
        reason: '',
        isSubmitModel: '0',
        submitModelType: '',
        attachmentInfoList: [],
        mobile: '',
        provinces: '', //省
        address: '', //地址
        phoneArea: '',
        contactWay: '1',
      },
      clinicAddressList: [],
      address: '',
      CITY: [],
      cityData: JSON.parse(sessionStorage.getItem('cityData')),
      contactWayList: [
        { idx: 0, key: '1', value: this.$t('cases.createUser.sjhm') },
        { idx: 1, key: '2', value: this.$t('birdie.gddh') },
      ],
      siliconeRubberModel: [
        {
          key: 'silicagel_print_model',
          value: this.$t('yssh.smqj'),
          enValue: 'The door to take',
        },
      ],
      digitalModelFile: [
        {
          key: 'local_upload',
          value: this.$t('yssh.bdsc'),
          enValue: 'Upload',
        },
        {
          key: 'iTero',
          value: this.$t('yssh.itero'),
          enValue: 'iTero',
        },
        {
          key: 'other',
          value: this.$t('yssh.qt'),
          enValue: 'Others',
        },
      ],
      fileNameObj: {
        other_file_reissue: [],
        iTero_reissue: '',
        sharp_upjaw_reissue: '',
        sharp_downjaw_reissue: '',
      },
      curePlanInfo: {},
      showTooth: false,
      showBatchInfo: false,
      toothPreLowerJawBatchCount: [],
      toothPreUpJawBatchCount: [],
      reissueDetail: {},
      uploading: false,
    };
  },
  watch: {
    modelType: {
      handler: function (nval) {
        this.resultObj['submitModelType'] = nval;
      },
    },
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
    }),
    renderBatchTips() {
      return function (key) {
        const stepList = Object.keys(this[key]).sort();
        let str = '';
        stepList.forEach((item) => {
          str += `${item}（${this.plasticsObj[this[key][item]] || ''}）、`;
        });
        return str.substring(0, str.length - 1);
      };
    },
    renderClassName() {
      return function (index, current, key) {
        if (index in this[key] && this[key][index] === current.id) {
          return 'plastics-item-active';
        } else {
          return 'plastics-item';
        }
      };
    },
  },
  async mounted() {
    await this.getPlasticsList();
    this.getSendAddressList();
    this.getContent();
    getQiniuToken().then((data) => {
      this.uploadToken = data;
    });
    if (
      this.caseDetails['curePlanInfo'].nowStepUpjaw !== 0 ||
      this.caseDetails['curePlanInfo'].nowStepDownjaw !== 0
    ) {
      this.showTooth = true;
    } else {
      this.showTooth = false;
    }
    if (this.reissueId !== '') {
      bracesReissueDetail(this.reissueId).then((res) => {
        const {
          reason,
          isSubmitModel,
          receiveType,
          addressId,
          // toothUpJawStep,
          // toothDownJawMaterial,
          submitModelType,
          // toothDownJawStep,
          // toothUpJawMaterial,
          batchInfo,
        } = res;
        // const upStep = "toothUpJawStep" in res ? toothUpJawStep.split(",") : [];
        // const downStep =
        //   "toothDownJawStep" in res ? toothDownJawStep.split(",") : [];
        // const upMaterial =
        //   "toothUpJawMaterial" in res ? toothUpJawMaterial.split(",") : [];
        // const downMaterial =
        //   "toothDownJawMaterial" in res ? toothDownJawMaterial.split(",") : [];
        this.reissueDetail = res;
        if (this.$route.query.isLog) {
          batchInfo.productionContentList
            .map((v) => {
              if (v.type !== '4') {
                if (v.position === '1') {
                  if (this.plasticsObj[v.plasticSheetId]) {
                    this.toothUpStepInfo[v.step] = v.plasticSheetId;
                  }
                }
                if (v.position === '2') {
                  if (this.plasticsObj[v.plasticSheetId]) {
                    this.toothDownStepInfo[v.step] = v.plasticSheetId;
                  }
                }
              }
            })
            .filter(Boolean);
          // upStep.forEach((item, index) => {
          //   this.toothUpStepInfo[item] = upMaterial[index];
          // });
          // downStep.forEach((item, index) => {
          //   this.toothDownStepInfo[item] = downMaterial[index];
          // });
          this.renderBraceInfo(res);
          if (receiveType === '3') {
            this.doctorAddressId = addressId;
            this.doctorAddressInfo = res['addressInfo'];
          }
          this.resultObj = {
            ...this.resultObj,
            reason,
            isSubmitModel,
            receiveType,
            submitModelType,
            receiveAddressId: addressId || '',
          };
        } else {
          this.renderBraceInfo(this.caseDetails);
        }
      });
    } else {
      this.renderBraceInfo(this.caseDetails);
    }
    if (
      this.caseDetails.hasOwnProperty('selfDeliverFlag') &&
      this.caseDetails.selfDeliverFlag == '1'
    ) {
      this.siliconeRubberModel = [
        ...this.siliconeRubberModel,
        {
          key: 'self_deliver',
          value: this.$t('yssh.zxyj'),
          enValue: 'By mail',
        },
      ];
    }
    this.getCity();
  },
  methods: {
    get,
    showDoctorAddressDia() {
      this.showDoctorAddress = true;
    },
    convertStepsToRanges(stepsArray) {
      if (stepsArray.length === 0) {
        return []; // 如果输入数组为空，返回空数组
      }

      let start = 1;
      const result = [];
      stepsArray.forEach((step) => {
        const end = start + step - 1;
        result.push(`${start}-${end}`);
        start = end + 1;
      });

      return result;
    },
    countStep(total, batch) {
      const batchArr = batch?.split(',').map((v) => Number(v)) || [];
      return batchArr?.map((v, index) => {
        const startBatch = batchArr.slice(index, batchArr.length + 1);
        const endBatch = batchArr.slice(0, index + 1);
        const startCount = total - startBatch.reduce((a, b) => a + b) + 1;
        const endCount = endBatch.reduce((a, b) => a + b);
        return endCount < startCount ? '0' : `${startCount}-${endCount}`;
      });
    },
    getContent() {
      detailCurePlan(this.$route.query.curePlanId).then((data) => {
        const {
          productionContentList,
          maxillarySteps,
          mandibularSteps,
          maxillary,
          mandibular,
        } = data;

        const upList = [];
        const downList = [];
        const _ups = this.countStep(maxillary, maxillarySteps);

        const _downs = this.countStep(mandibular, mandibularSteps);
        _ups.forEach((v, index) => {
          upList?.push([]);
          downList?.push([]);
          const ups = v?.split('-');
          const downs = _downs[index]?.split('-');
          productionContentList.forEach((item) => {
            if (item.position === '1' && item.type !== '4') {
              if (Number(ups[0]) <= item.step && item.step <= Number(ups[1])) {
                upList?.[index]?.push(item);
              }
            }
            if (item.position === '2' && item.type !== '4') {
              if (
                Number(downs[0]) <= item.step &&
                item.step <= Number(downs[1])
              ) {
                downList?.[index]?.push(item);
              }
            }
          });
        });

        const upSheetList = [];
        upList.forEach((v) => {
          upSheetList.push(
            [
              ...new Set(
                v.map((item) => this.plasticsObj[item.plasticSheetId])
              ),
            ].join(',')
          );
        });
        const downSheetList = [];
        downList.forEach((v) => {
          downSheetList.push(
            [
              ...new Set(
                v.map((item) => this.plasticsObj[item.plasticSheetId])
              ),
            ].join(',')
          );
        });
        this.upSteps = _ups;
        this.downSteps = _downs;
        this.upSheetList = upSheetList;
        this.downSheetList = downSheetList;
      });
    },
    renderAddressText() {
      const {
        receiveUser,
        sender,
        contactWay,
        phoneArea,
        phone,
        provinceName,
        cityName,
        areaName,
        address,
      } = this.doctorAddressInfo;
      return `${sender || receiveUser}- ( ${
        contactWay === '2' ? `${phoneArea}-${phone}` : phone
      } ) -${provinceName}-${cityName}-${areaName}-${address}`;
    },
    confirmAddress() {
      this.visible = false;
      this.getSendAddressList();
    },
    choosenCurrent(info) {
      this.doctorAddressInfo = info;
      this.doctorAddressId = info.id;
      this.showDoctorAddress = false;
    },
    addDoctorAddress() {
      this.isEdit = false;
      this.visible = true;
    },
    editCurrent(info) {
      this.editId = info.id;
      this.isEdit = true;
      this.visible = true;
    },
    getSendAddressList() {
      PublicSendAddressList({
        refCode: localStorage.getItem('userId'),
        refType: 'DOCTOR',
      }).then((res) => {
        this.addressList = res;
        res.forEach((item) => {
          if (this.doctorAddressInfo?.id === item.id) {
            this.doctorAddressInfo = item;
          }
        });
      });
    },
    async toExpress() {
      const reissueDetail = await bracesReissueDetail(
        this.$route.query.logisticsReissueId
      );
      if ('modelLogisticsId' in reissueDetail) {
        sessionStorage.setItem('logisticsId', reissueDetail.modelLogisticsId);
      }
      this.$emit('showExpress', reissueDetail['submitModelType'], true);
    },
    handleShowBatchInfo() {
      this.showBatchInfo = true;
    },
    renderBraceInfo(data) {
      const { curePlanInfo } = data;
      this.curePlanInfo = curePlanInfo;
      const {
        toothBatchPlasticSheet,
        mandibularSteps,
        maxillarySteps,
        nowStepUpjaw,
        nowStepDownjaw,
      } = curePlanInfo;
      console.log({
        mandibularSteps,
        maxillarySteps,
        nowStepUpjaw,
        nowStepDownjaw,
      });

      const lower = mandibularSteps?.split(',') || [];
      const upper = maxillarySteps?.split(',') || [];
      this.toothPreLowerJawBatchCount = lower;
      this.toothPreUpJawBatchCount = upper;
      this.plasticsBatchList = toothBatchPlasticSheet?.split(',') || [];
      if (upper !== undefined) {
        this.upperCountInfo = this.renderBatchItems(upper, nowStepUpjaw);
      }
      if (lower !== undefined) {
        this.lowerCountInfo = this.renderBatchItems(lower, nowStepDownjaw);
      }
    },
    tabsChanged(key) {
      this.exDefaultKey = key;
      this.resultObj['submitModelType'] = key;
    },
    otherListInit() {
      notifyMsg(this, 'warning', this.$t('cases.createImage.zdxz'));
    },
    changeContactWay(e) {
      this.resultObj.phoneArea = '';
      this.resultObj.phone = '';
    },
    deleteOtherFile(index, id) {
      this.fileNameObj['other_file_reissue'].splice(index, 1);
      this.resultObj.attachmentInfoList = filter(
        map(this.resultObj.attachmentInfoList, (item) => {
          if (item.id === id) {
            return;
          }
          return item;
        })
      );
    },
    copyCasesNumber() {
      const input = document.querySelector('#caseNumber');
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        notifyMsg(this, 'success', this.$t('cases.createImage.fzcg'));
      }
    },
    beforeFileUpload(file, key, type) {
      const { name } = file;
      const _this = this;
      const filedTypeFilter = type === 'zip' ? ['zip', 'rar'] : ['stl'];
      const fileType = name?.split('.').pop();
      if (filedTypeFilter.includes(fileType)) {
        this.uploading = true;
        const { attachmentInfoList } = _this.resultObj;
        const list =
          key !== 'other_file_reissue'
            ? attachmentInfoList.filter((item) => item.fileKey !== key)
            : attachmentInfoList;
        const aBlob = new Blob([file]);
        const ts = new Date().getTime();
        const observable = QINIU.upload(
          aBlob,
          ts + '_' + name,
          this.uploadToken
        );
        observable.subscribe({
          error(err) {
            _this.uploading = false;
          },
          complete(res) {
            const { hash } = res;
            _this.uploading = false;
            _this.resultObj.attachmentInfoList = [
              ...list,
              {
                fileKey: key === 'iTero' ? 'iTero_reissue' : key,
                fileName: ts + '_' + name,
                realFileName: name,
                fileType: type,
                fileUrl: hash,
                fileSource: 'DOCTOR',
              },
            ];
            if (key === 'other_file_reissue') {
              if (!_this.fileNameObj[key]) {
                _this.$set(_this.fileNameObj, key, [{ name: ts + '_' + name }]);
              } else {
                _this.$set(_this.fileNameObj, key, [
                  ..._this.fileNameObj[key],
                  { name: ts + '_' + name },
                ]);
              }
            } else {
              _this.fileNameObj[key === 'iTero' ? 'iTero_reissue' : key] =
                ts + '_' + name;
            }
            notifyMsg(_this, 'success', _this.$t('common.message.wjsccg'));
          },
        });
      } else {
        notifyMsg(
          this,
          'warning',
          `${this.$t('common.message.qxz')}${type}${this.$t(
            'common.message.wjsc'
          )}`
        );
      }
      return false;
    },
    uploadSuccess(res, filed, name, file, type) {
      this.$forceUpdate();
      this.uploading = false;
      const { resultObj } = this;
      resultObj[filed] = { ETag: res.key, nanoId: res.key };
      let index = this.carouselList.length;
      this.carouselList.splice(
        this.$route.query.productType == 'F'
          ? this.yysdIdxObj[filed]
          : this.idxObj[filed],
        0,
        {
          nanoId: res.key,
          name,
          ETag: res.key,
          href: this.$PicPrefix + res.hash,
          filed,
        }
      );
      this.carouselList.map((item, index) => {
        this.carouselList[index].index = index + '';
      });
      notifyMsg(this, 'success', this.$t('common.message.tpsccg'));
    },
    beforeUpload(file) {
      this.nanoid = nanoid();
      const supportedFormats = ['image/jpeg', 'image/png', 'image/bmp'];
      let isLt20M = file.size / 1024 / 1024 < 20;
      if (!supportedFormats.includes(file.type)) {
        notifyMsg(this, 'warning', this.$t('common.message.supportedFormats'));
        return false;
      } else if (!isLt20M) {
        notifyMsg(this, 'warning', this.$t('common.message.isLt20M'));
        return false;
      } else {
        return true;
      }
    },
    uploadError() {
      return notifyMsg(this, 'error', this.$t('common.message.scsb'));
    },
    stagingData() {
      let toothUpJawStep = [];
      let toothDownJawStep = [];
      let toothUpJawMaterial = [];
      let toothDownJawMaterial = [];
      Object.keys(this.toothDownStepInfo).forEach((item) => {
        toothDownJawStep.push(item);
        toothDownJawMaterial.push(this.toothDownStepInfo[item]);
      });
      Object.keys(this.toothUpStepInfo).forEach((item) => {
        toothUpJawStep.push(item);
        toothUpJawMaterial.push(this.toothUpStepInfo[item]);
      });
      const { provinces } = this.resultObj;
      const province = provinces[0] || '';
      const city = provinces[1] || '';
      const area = provinces[2] || '';
      const result = {
        ...this.resultObj,
        province,
        city,
        area,
        toothUpJawStep: toothUpJawStep.join(','),
        toothDownJawStep: toothDownJawStep.join(','),
        toothUpJawMaterial: toothUpJawMaterial.join(','),
        toothDownJawMaterial: toothDownJawMaterial.join(','),
      };
      if (result.receiveType === '3') {
        result['receiveAddressId'] = this.doctorAddressId;
      }
      return result;
    },
    sendExpress() {
      this.$emit('showExpress', 'silicagel_print_model');
    },
    buttonHandlerChange(val, parentKey) {
      this.$forceUpdate();
      if (val['receiveType'] === '2') {
        this.resultObj.receiveAddressId = '';
      } else if (val['receiveType'] === '1') {
        this.resultObj.receiveAddressId = '';
        this.resultObj = {
          ...this.resultObj,
          receiveUser: '',
          receiveType: '1',
          address: '',
          phone: '',
          provinces: '', //省
          phoneArea: '',
          contactWay: '1',
        };
        this.address = '';
        this.resultObj.contactWay = '1';
      } else if (val['receiveType'] === '3') {
        this.resultObj.receiveAddressId = '';
        this.resultObj = {
          ...this.resultObj,
          receiveUser: '',
          receiveType: '1',
          address: '',
          phone: '',
          provinces: '', //省
          phoneArea: '',
          contactWay: '1',
        };
        this.address = '';
      }
      if (val['isSubmitModel'] === '0') {
        this.resultObj['submitModelType'] = '';
      }
      if (this.resultObj['submitModelType'] !== val['submitModelType']) {
        this.fileNameObj = {
          other_file_reissue: [],
          iTero_reissue: '',
          sharp_upjaw_reissue: '',
          sharp_downjaw_reissue: '',
        };
        this.$set(this.resultObj, 'attachmentInfoList', []);
      }
      if (val['submitModelType'] === 'self_deliver') {
        if ('modelLogisticsId' in this.reissueDetail) {
          sessionStorage.setItem(
            'logisticsId',
            this.reissueDetail.modelLogisticsId
          );
        }
        this.$emit('showExpress', 'self_deliver');
      }
      this.resultObj = Object.assign(this.resultObj, val);
    },
    changeItemStep(item, current, key) {
      this.$forceUpdate();
      if (this[key][item] === current['id']) {
        delete this[key][item];
      } else {
        this[key][item] = current['id'];
      }
    },
    handleShow(item, current, key) {
      this.$forceUpdate();
      if (key === 'lower') {
        if (!(item in this.toothDownStepInfo)) {
          this.toothDownStepInfo[item] =
            this.plasticsSheetList[current]?.id || '';
        }
      } else {
        if (!(item in this.toothUpStepInfo)) {
          this.toothUpStepInfo[item] =
            this.plasticsSheetList[current]?.id || '';
        }
      }
    },
    async getPlasticsList() {
      let clinicAddressList = await clinicAddress({
        refCode: this.caseDetails['clinicId'],
        refType: 'CLINIC',
      });
      let list = [];
      clinicAddressList.map((item) => {
        list.push({
          value: item.id,
          label: `${item.receiveUser}- ( ${
            item.contactWay === '2'
              ? `${item.phoneArea}-${item.phone}`
              : item.phone
          } ) -${item.provinceName}-${item.cityName}-${item.areaName}`,
        });
      });
      this.clinicAddressList = list;
      PublicPlasticSheetList({
        removeFlag: '0',
        type: '1',
      }).then((data) => {
        let obj = {};
        data.map((item) => {
          obj[item.id] = item.name;
        });
        this.plasticsObj = obj;
        this.plasticsSheetList = data;
        this.isLoading = false;
      });
    },
    getMessage() {
      //张xx  13259030322 浙江省杭州市下城区xx大厦xx号
      const [res] = AddressParse.parse(this.address);
      let provinces = [];
      if (res.province) {
        const list = this.CITY.filter((item) => item.name === res.province);
        if (list.length) {
          const cityList = list[0].sub.filter((item) => item.name === res.city);
          const areaList = cityList[0].sub.filter(
            (item) => item.name === res.area
          );
          provinces.push(list[0].id);
          if (cityList.length) {
            provinces.push(cityList[0].id);
            if (areaList.length) provinces.push(areaList[0].id);
          }
        }
      }
      if (res.mobile) {
        this.resultObj.phone = res.mobile;
        this.resultObj.contactWay = '1';
      }
      if (res.phone) {
        let areaPhone = res['phone'].split('-');
        this.resultObj.contactWay = '2';
        this.resultObj.phoneArea = areaPhone[0];
        this.resultObj.phone = areaPhone[1];
      }
      this.resultObj.provinces = provinces;
      this.resultObj.receiveUser = res.name;
      this.resultObj.address = res.details;
    },
    resetMessage() {
      this.address = '';
    },
    renderBatchItems(steps, durStep) {
      // 用durStep作为截止
      let count = 0;
      let splitIndex = null;
      for (let i = 0; i < steps.length; i++) {
        const n = Number(steps[i]);
        count += n;
        if (count === durStep) {
          splitIndex = i + 1;
          break;
        }
      }
      const stepsSlice = steps.slice(0, splitIndex);
      let start = 1;
      const list = stepsSlice
        ?.map((v) => {
          const step = Number(v);
          const end = start + step - 1;
          const res = { start, end };
          start += step;
          return res;
        })
        ?.filter(({ start, end }) => start < end);
      return list;
    },
    getCity() {
      getCityList().then((data) => {
        this.CITY = data;
        const { province, city } = this.resultObj;
        const list = this.CITY.filter((item) => item.id === province);
        if (list.length) {
          this.theCityList = this.CITY.filter(
            (item) => item.id === province
          )[0].sub;
          this.countyList = this.theCityList.filter(
            (item) => item.id === city
          )[0].sub;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-batch-data {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 204px;
    height: 140px;
  }

  div {
    font-size: 14px;
    line-height: 20px;
    color: #999999;
    margin-top: 24px;
  }
}

.dia-header {
  height: 58px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .close-dia {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  .title {
    font-size: 18px;
    color: #333333;
    display: flex;
    align-items: center;

    .add-address {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 24px;
      font-size: 14px;
      color: #fcc80e;

      img {
        width: 24px;
        margin-right: 4px;
      }
    }
  }
}

.dia-content {
  padding: 10px 30px;
  height: 600px;
  overflow-y: auto;

  .no-data {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 184px;
      margin-bottom: 24px;
    }

    span {
      font-size: 14px;
      line-height: 20px;
      color: #999999;
    }
  }

  .address-item {
    margin-bottom: 10px;
  }
}

.dia-footer {
  height: 30px;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.12);
}

/deep/ .address-dia .el-dialog__header {
  padding: 0px;
}

/deep/ .address-dia .el-dialog__body {
  padding: 0px;
}

/deep/ .address-dia .el-dialog {
  border-radius: 8px;
  margin-top: 15vh !important;
}

.doctor-address {
  display: flex;
  align-items: center;

  .address-label {
    font-size: 16px;
    margin-right: 20px;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #ed4027;
      margin-right: 4px;
    }
  }

  .single_right {
    display: flex;
    align-items: center;

    span {
      cursor: pointer;
      color: #fcc80e;
      font-size: 14px;
    }
  }
}

.doctor-select,
.doctor-select-active {
  cursor: pointer;
  width: 276px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  padding: 0 20px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 32px;
}

.doctor-select {
  background-color: #f4f4f4;
  color: #999999;
}

.doctor-select-active {
  border: 1px solid #bbbbbb;
  color: #333333;
}

.batch-info-content {
  max-height: 200px;
  overflow-y: auto;
  //padding: 0 40px;

  .batch-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333333;
    white-space: nowrap;
    margin-bottom: 24px;

    .item-label {
      width: 100px;
      text-align: right;
    }

    .item-upjaw {
      min-width: 20px;
      margin: 0 10px;
    }

    .item-downjaw {
      min-width: 20px;
      margin-right: 10px;
    }
  }
}

::v-deep .modal_content .el-dialog__header {
  //border-bottom: 1px solid #ebebeb;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

::v-deep .modal_content .el-dialog__footer {
  box-shadow: 0px -2px 8px 0px rgba(51, 51, 51, 0.08);
  padding: 20px !important;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;

  .footer-cancel,
  .footer-confirm {
    cursor: pointer;
    width: 96px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    color: #333333;
  }

  .footer-cancel {
    margin-right: 32px;
    border: 1px solid #bbbbbb;
  }

  .footer-confirm {
    background-color: #fcc80e;
  }
}

.plastics-content {
  height: 188px;
  overflow-y: auto;

  .plastics-item,
  .plastics-item-active {
    cursor: pointer;
    height: 36px;
    padding: 8px;
    border-radius: 6px;
    font-size: 14px;
    display: flex;
    align-items: center;

    div {
      max-width: 80%;
      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover {
      background-color: #f9f9f9;
    }
  }

  .plastics-item-active {
    background-color: #f9f9f9;
    color: #fcc80e;
  }
}

.receive-address {
  display: flex;
  align-items: center;

  .address-label {
    font-size: 16px;
    margin-right: 20px;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #ed4027;
      margin-right: 4px;
    }
  }

  .address-select {
    width: 316px;
    height: 40px;
  }
}

.button-filed-item {
  display: flex;
  align-items: center;

  .label {
    margin-bottom: 20px;
    margin-right: 20px;
    white-space: nowrap;
    font-size: 16px;
  }
}

.print-model {
  margin-bottom: 30px;
  margin-left: 1.2rem;

  .tips {
    font-size: 14px;
    line-height: 20px;
    color: #666;
    margin-bottom: 10px;
  }
}

.send-express-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 436px;
  height: 68px;
  border-radius: 4px;
  background-color: #f4f4f4;
  padding: 16px 20px;
  color: #333;
  font-size: 14px;
  line-height: 20px;

  .copy {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: url('../../../../../common/imgs/case/case_icon_plus.png')
      no-repeat center;
  }

  .send {
    .express-icon {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }

    cursor: pointer;
    padding: 0 12px;
    height: 36px;
    border-radius: 4px;
    background-color: #fcc80e;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.upload-tips {
  font-size: 14px;
  line-height: 20px;
  color: #333;
  margin-bottom: 10px;
}

.up_load_def {
  position: absolute;
  left: 0;
  top: 0.5rem;
  right: 0;
  height: 1rem;
  z-index: 9;
  background-size: 100% 100%;
}

.s_c__up {
  display: flex;
  align-items: center;
  font-weight: normal;
  margin-bottom: 0.16rem;
  font-size: 14px;
  color: $main_theme_color_333;

  .upload-file {
    margin: 0 0.2rem;
  }

  .up_load {
    display: inline-block;
    height: 0.4rem;
    line-height: 0.4rem;
    margin: 0 0.16rem;
    border-radius: 0.03rem;
    outline: none;
    padding: 0 0.2rem;
    position: relative;
  }

  .up_icon {
    display: inline-block;
    width: 0.24rem;
    height: 0.24rem;
    background-image: url('/static/images/createCases/web_doc_upload.png');
    background-size: 100% 100%;
    cursor: pointer;
  }

  .up_status {
    color: #5cb6ff;
  }

  .up_file {
    display: flex;
    align-items: center;
    margin-left: 0.2rem;

    .up_status {
      color: #5cb6ff;
      margin-left: 0.1rem;
    }
  }

  .other_file {
    margin-bottom: 0.1rem;
  }
}

.braces-reissue-table {
  padding: 30px 30px 100px;

  .loading-content {
    display: flex;
    height: 100%;
    justify-content: center;
    padding-top: 100px;

    .loading-icon {
      width: 48px;
      height: 48px;
    }
  }

  .batch-info-card {
    padding: 16px;
    background-color: #f4f4f4;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 18px;
    line-height: 26px;
    color: #333333;
    position: relative;

    .to-express {
      white-space: nowrap;
      position: absolute;
      font-size: 18px;
      right: 16px;
      top: 16px;

      span {
        font-size: 18px;
        margin-left: 0px;
        color: #fcc80e;
      }
    }

    .batch-info-tips {
      color: #ed4027;
      margin-bottom: 8px;
    }

    span {
      cursor: pointer;
      color: #fcc80e;
      margin-left: 34px;
      font-size: 14px;
    }
  }

  .questions-content {
    .case-title {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 26px;
      color: #333;
      margin: 10px 0;

      .link {
        display: flex;
        align-items: center;
        color: #fcc80e;
        margin-left: 10px;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .required-title {
      display: flex;
      align-items: center;

      &::before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #ed4027;
        margin-right: 4px;
      }
    }

    .wrap {
      padding-left: 20px;

      .upper-batch-tabs,
      .lower-batch-tabs {
        margin-top: 10px;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 28px;

        .batch-tabs-item,
        .batch-tabs-item-active {
          cursor: pointer;
          width: fit-content;
          height: 28px;
          margin-right: 48px;
          color: #333333;

          &:first-child {
            margin-left: 20px;
          }
        }

        .batch-tabs-item-active {
          color: #fcc80e;
          position: relative;

          &::after {
            position: absolute;
            content: '';
            width: 100%;
            height: 1px;
            background-color: #fcc80e;
            bottom: 0;
            left: 0;
          }
        }
      }

      .upper-batch-info,
      .lower-batch-info {
        width: 650px;
        margin: 10px 0;
        padding-left: 116px;
        display: flex;
        flex-wrap: wrap;

        .bacth-info-item,
        .bacth-info-item-active {
          cursor: pointer;
          width: 32px;
          height: 32px;
          border-radius: 4px;
          margin: 0 20px 20px 0;
          border: 1px solid #bbbbbb;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          color: #999999;
        }

        .bacth-info-item-active {
          border: 1px solid #fcc80e;
          color: #fcc80e;
        }
      }

      .batch-changed-tips {
        width: 984px;
        padding: 10px 20px;
        background-color: #f4f4f4;
        border-radius: 4px;
        font-size: 14px;
        line-height: 20px;
        color: #333333;

        .batch-tips-item {
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }

    .question-tips {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
      color: #999999;
    }
  }
}

.f_single {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;

  .f_single_left {
    font-size: 16px;
    margin-right: 0.2rem;
    width: 96px;
    text-align: right;
    float: right;
    color: $main_theme_color_333;
    white-space: nowrap;

    span {
      color: $main_theme_color_red;
      font-size: 16px;
    }
  }

  .f_single_right {
    flex: 1;
    font-size: 14px;
    border-radius: 0.02rem;
    color: $main_theme_color_333;
    position: relative;

    input {
      width: 2.74rem;
      height: 0.4rem;
      box-sizing: border-box;
      outline: none;
      padding-left: 0.19rem;
      border: 1px solid #bbbbbb;
      border-radius: 4px;
    }

    .select_box {
      display: flex;
      align-items: center;
      margin-bottom: 0.35rem;

      select {
        width: 2.74rem;
        height: 0.4rem;
        border: none;
        background-color: #f4f4f4;
        padding: 0.1rem 0.19rem;
        box-sizing: border-box;
      }
    }

    .iup_box {
      width: 5.68rem;

      input {
        width: 100%;
        height: 0.42rem;
      }
    }
  }
}

.message-add {
  margin-top: 20px;
  height: 100px;
  width: 600px !important;
  border: 1px solid #bbbbbb;
  border-radius: 5px;
  padding: 10px;
}

.message-add::placeholder {
  font-size: 14px;
  line-height: 20px;
  color: rgb(153, 153, 153);
}

.message-parse {
  display: inline-block;
  margin-left: 10px;
  padding-bottom: 10px;
  color: rgb(252, 200, 14);
  cursor: pointer;
}

.message-reset {
  display: inline-block;
  margin-left: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.modal_header {
  width: 100%;
  display: flex;
  position: relative;
  font-size: 16px;

  .case_title_box {
    text-align: center;
    width: 100%;
    border-bottom: 0.01rem solid #ebebeb;

    .title_icon {
      width: 45px;
      height: 45px;
      margin: 10px auto 16px;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .el-icon-close {
    position: absolute;
    right: 10px;
    top: -5px;
    font-size: 24px;
    font-weight: 800;
    cursor: pointer;
    color: #9e9999;
    transition: 0.2s all;
  }

  .el-icon-close:hover {
    color: $main_theme_color;
    transition: 0.2s all;
  }
}

.el-icon-close {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 24px;
  font-weight: 800;
  cursor: pointer;
  color: #9e9999;
  transition: 0.2s all;
}

.el-icon-close:hover {
  color: $main_theme_color;
  transition: 0.2s all;
}
</style>
